import Vue from 'vue'
import App from './App.vue'
import router from './router'
import wayCode from './utils/wayCode';
import config from './config'
import 'amfe-flexible'
Vue.config.productionTip = false

/**
import { Toast } from 'vant';
import 'vant/lib/toast/style';
Vue.prototype.showLoading = function (msg) {
    Toast.loading({
        forbidClick: true,
        duration: 0,
        loadingType: 'spinner',
        message: msg || "加载中..."
    })
}
Vue.prototype.hideLoading = function () {
    Toast.clear()
}
*/

/**
import VConsole from "vconsole"
Vue.prototype._vConsole = new VConsole({
    onReady: function () {
        console.info("VConsole初始化完成")
        // this.hideSwitch()
    }
})
 */

/**
 * 路由守卫
 *
 * @author terrfly
 * @site https://www.jeepay.vip
 * @date 2021/5/8 07:18
 */
router.beforeEach((to, from, next) => {

    console.log("进入路由守卫",from, to)

    // from: 当前导航正要离开的路由
    // to: 即将要进入的目标路由对象
    // alert("from->" + from.name)
    // alert("to->" + to.name)
    if (to.name === "Oauth2Callback" && from.name) {
        if(wayCode.getPayWay().wayCode ==="WX_JSAPI") window.WeixinJSBridge.call('closeWindow')
        else if(wayCode.getPayWay().wayCode ==="ALI_JSAPI") window.AlipayJSBridge.call('closeWebview')
        return false
    }

    // 如果在免登录页面则直接放行
    if (config.errorPageRouteName.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
      return false
    }

    if (config.passGuardRouteList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
      return false
    }

    //获取不到参数
    let token = to.params[config.urlTokenName];
    // let token = 'test';  // 不提交
    if(token){  //放置token信息
        config.cacheToken = token;
    }

    if(!config.cacheToken) {
        next({ name: config.errorPageRouteName, params: { errInfo: "请通过二维码进入支付页面！" }, replace: true })
        return false;
    }

    //获取不到支付类型, 需要跳转到错误页面
    if( ! wayCode.getPayWay() ) {
        next({ name: config.errorPageRouteName, params: { errInfo: "不支持的支付方式！ 请在微信/支付宝/银联应用内扫码进入！" }, replace: true })
        return false;
    }

    next()
})

Vue.prototype.$config = config;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
